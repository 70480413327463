import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import {
  Banner,
  Section,
  FeaturedCard,
  Carousel,
  CardList,
  Card,
  Prefooter,
  Swirl
} from '../partials/components';
import { PageTransition } from '../helpers/pageTransitions';

class IndexPage extends Component {
  render() {
    const {
      homepage: {
        seoMetaTags,
        bannerImage,
        bannerTitle,
        bannerCaption,
        title,
        leadText,
        productCarousel,
        bottomSectionTitle,
        bottomSectionText,
        featuredCollections,
        featureSlider
      },
      globalConfig: { defaultPathways, contactPage }
    } = this.props.data;

    const allPathways = [...defaultPathways];

    const productCarouselItems = productCarousel.map(item => ({
      heading: item.title,
      subheading: item.displaySubtitle,
      caption: item.shortDescription,
      defaultImage: item.heroImage,
      thumb: item.thumb,
      link: {
        title: 'More',
        path: item.path
      }
    }));

    return (
      <PageTransition>
        <HelmetDatoCms seo={seoMetaTags} />

        <Banner
          image={bannerImage}
          title={bannerTitle}
          caption={bannerCaption}
        />

        <Section
          animate={true}
          padding={'double'}
          heading={title}
          lead={leadText}
        >
          <Carousel
            items={productCarouselItems}
            showThumbNavigation={true}
            moduleStyle={'products'}
          />
        </Section>

        <Section
          animate={true}
          swirlStart={<Swirl type={'swirl from white'} />}
          swirlEnd={<Swirl type={'swirl to white'} desktopOnly={true} />}
        >
          {featuredCollections.map((card, i) => (
            <FeaturedCard key={`homepage-featured-cards-${i}`} {...card} />
          ))}
        </Section>

        <Section
          animate={true}
          padding={'double top'}
          heading={bottomSectionTitle}
          lead={bottomSectionText}
        >
          <Carousel items={featureSlider} moduleStyle={'Features'} />
        </Section>

        <Section animate={true} background={'light-gray'} padding={'both'}>
          <CardList fullWidth={true}>
            {allPathways.map((pathway, i) => (
              <Card key={`pathway-${i}`} {...pathway} />
            ))}
          </CardList>
        </Section>
        <Section animate={true} background={'light-gray'} padding={'top'}>
          <Prefooter contactPagePath={contactPage.path} />
        </Section>
      </PageTransition>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery($language: String) {
    homepage: datoCmsHome(locale: { eq: $language }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      leadText
      title
      bannerTitle
      bannerCaption
      bannerImage {
        fluid(
          maxWidth: 1600
          imgixParams: { w: "1600", h: "1080", fit: "crop", q: 70 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      featuredCollections {
        ...FeaturedCard
      }
      bottomSectionTitle
      bottomSectionText
      featureSlider {
        ...Slide
      }

      ...ProductCarousel
    }
    globalMeta: datoCmsSite(locale: { eq: $language }) {
      name
      locales
      globalSeo {
        titleSuffix
        twitterAccount
        siteName
        facebookPageUrl
        fallbackSeo {
          description
          image {
            url
          }
          title
          twitterCard
        }
      }
      faviconMetaTags {
        tags
      }
    }
    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;
